import React, { FC } from "react";
import PropTypes from "prop-types";
// import { css } from '@emotion/react';
// import { theme } from '../../styles';
import { easeInOut } from "@popmotion/easing";
import posed from "react-pose";

export const AnimatedScroll = posed.span({
  enter: {
    opacity: 1,
    y: 0,
    rotate: 90,
    transition: {
      opacity: {
        duration: 1000,
        ease: easeInOut,
      },
      y: ({ from }) => ({
        type: "keyframes",
        values: [from, 100, from],
        duration: 2000,
        ease: easeInOut,
        loop: Infinity,
      }),
    },
  },
  exit: {
    rotate: 90,
    opacity: 0,
    y: 0,
  },
});

const Body: FC<any> = (props) => (
  <>
    {props.cap ? (
      <div>
        <div className="cap-symbol">{"// "}</div>
        <div className="cap">{props.cap}</div>
      </div>
    ) : null}
    {props.title ? (
      <h1 className={`heading ${props.titleClass}`}>
        {props.title} {<span className="special">{props.specialTitle}</span>}{" "}
        <br />
      </h1>
    ) : null}
    {props.paragraph ? (
      <div className={`${props.paragraphClass}`}>{props.paragraph}</div>
    ) : null}
    {props.children}
    {props.scroll ? (
      <AnimatedScroll className="scroll2" initialPose="exit" pose="enter" />
    ) : null}
  </>
);

const HeroContainer: FC<any> = (props) => (
  <div className={`container-hero-${props.heroType || "home"}`}>
    {props.block ? (
      <>
        <div className={props.wrapper || ""}>
          <Body {...props} />
        </div>
      </>
    ) : props.divBlock ? (
      <>
        <div className={`div-block-${props.divBlock}`}>
          <Body {...props} />
        </div>
        {props.background && props.background}
      </>
    ) : (
      <Body {...props} />
    )}
  </div>
);

export default HeroContainer;

HeroContainer.propTypes = {
  heroType: PropTypes.string,
  paragraph: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  paragraphClass: PropTypes.string,
  title: PropTypes.string || PropTypes.element,
  titleClass: PropTypes.string,
  block: PropTypes.bool,
  divBlock: PropTypes.string,
  wrapper: PropTypes.string,
  scroll: PropTypes.bool,
  specialTitle: PropTypes.string,
  cap: PropTypes.string,
  background: PropTypes.object,
};
