import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

const PT2020 = (props: any) => {
  if (
    !props?.image?.localFile?.childImageSharp?.gatsbyImageData ||
    !props.link?.url
  ) {
    return null;
  }
  return (
    <div className="pt2020-col">
      <h3 className="pt2020-title">
        Project co-financed by
        <a
          className="pt2020-link"
          href={props.link.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          (more information)
        </a>
        <GatsbyImage
          image={props.image.localFile.childImageSharp.gatsbyImageData}
          alt={props.image.alt}
          className="pt2020-banner"
          imgStyle={{ objectFit: "scale-down" }}
        />
      </h3>
    </div>
  );
};

export default PT2020;

PT2020.propTypes = {
  link: PropTypes.object,
  image: PropTypes.object,
};
