import React from "react";
import PropTypes from "prop-types";

const BlueTag = (props: any) => (
  <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
    <div className="blue-row">
      <div className="client_desc-row">
        <h2 className="blue_row-title">Client</h2>
        <span className="blue_row-text">{props.client}</span>
      </div>
      <div className="client_desc-row">
        <h2 className="blue_row-title">Location</h2>
        <span className="blue_row-text">{props.location}</span>
      </div>
      {props.link ? (
        <div className="client_desc-row">
          <h2 className="blue_row-title">Live Project</h2>
          <a
            className="blue_row-text"
            href={props.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Link
          </a>
        </div>
      ) : null}
    </div>
  </div>
);

export default BlueTag;

BlueTag.propTypes = {
  client: PropTypes.string,
  location: PropTypes.string,
  link: PropTypes.string,
};
