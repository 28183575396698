import React from "react";
import PropTypes from "prop-types";

const CaseParagraph = (props: any) => (
  <div className="case_paragraph-col">
    <h3 className="case_paragraph-title">{props.title}</h3>
    <p className="case-paragraph">{props.body}</p>
  </div>
);

export default CaseParagraph;

CaseParagraph.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
};
