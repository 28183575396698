import React from "react";
import { graphql } from "gatsby";
import TaggedTextWithDescription from "../components/TaggedTextWithDescription";
import ParallaxWrapper from "../components/ParallaxWrapper";
import Banner from "../components/Banner";
import ProjectContainer from "../components/ProjectContainer";
import SEO from "../components/SEO";
import Button from "../components/Button";
import BlueTag from "../components/BlueTag";
import CaseParagraph from "../components/CaseParagraph";
import PT2020 from "../components/PT2020";

export const pageQuery = graphql`
  query ProjectQuery($uid: String!) {
    allPrismicProject {
      nodes {
        uid
        data {
          title
          cover {
            alt
            url
          }
        }
        id
      }
    }
    prismicProject(uid: { eq: $uid }) {
      id
      uid
      data {
        link
        client
        codename
        location
        project_area
        project_description
        project_summary
        role
        technologies
        title
        link
        description
        keywords
        image {
          url
        }
        canonical_url {
          url
        }
        section_orientation
        logo {
          url
          alt
          dimensions {
            width
          }
        }
        project_image {
          alt
          url
        }
        cover {
          alt
          url
        }
        banner {
          url
          alt
        }
        showcase {
          image1 {
            alt
            url
          }
        }
        financed
        financed_banner {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                tracedSVGOptions: { background: "#1e22aa", color: "#333" }
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
          }
        }
        project_link {
          url
        }
        challenge
        process
        future_plans
      }
    }
  }
`;
const getNextProject = (allProjects, projectIndex) => {
  if (allProjects && allProjects.length > 0) {
    if (projectIndex === allProjects.length - 1) {
      return allProjects[0];
    }
    return allProjects[projectIndex + 1];
  }
  return null;
};
const Project = (props: any) => {
  const data = props.data?.prismicProject?.data;
  const allProjects = props.data?.allPrismicProject?.nodes || [];
  const projectIndex = allProjects.findIndex(
    (project) => project.uid === (props.data?.prismicProject || {}).uid
  );
  const nextProject =
    getNextProject(allProjects, projectIndex) ||
    props.data?.prismicProject ||
    {};
  return (
    <>
      <SEO {...props.data.prismicProject.data} />
      <ParallaxWrapper
        title={data.title}
        classType="highpitch-bac"
        backgroudImage={data.cover.url}
        alt={data.cover.alt}
      />
      <div className="project-section">
        <BlueTag
          client={data.client}
          location={data.location}
          link={data.link}
        />
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div className="caseStudy_box">
            <TaggedTextWithDescription
              containerType="caseStudy"
              title={data.title}
              subtitle={data.project_area}
              bodyClass="p2 max"
              body={data.project_description}
            />
            <CaseParagraph title="Challenge" body={data.challenge} />
            {data.showcase ? (
              data.showcase.map((item, index) => (
                <Banner
                  src={item.image1.url}
                  alt={item.image1.alt}
                  key={`show-case-image-${index}`}
                />
              ))
            ) : (
              <Banner src={data.banner.url} alt={data.banner.alt} />
            )}
            <CaseParagraph title="Process" body={data.process} />

            <ProjectContainer
              leftSection={data.section_orientation === "Left"}
              rightSection={data.section_orientation === "Right"}
              image={data.project_image.url}
              sizes="(max-width: 767px) 90vw, (max-width: 991px) 46vw, 86vw"
              alt={data.project_image.alt}
              imageClass="image-16"
              projectSummary={data.project_summary}
              projectTechnologies={data.technologies}
            />
            {data?.future_plans && data?.future_plans?.length ? (
              <CaseParagraph title="Future Plans" body={data.future_plans} />
            ) : null}

            {data.project_link && data.financed_banner ? (
              <PT2020 link={data.project_link} image={data.financed_banner} />
            ) : null}
          </div>
        </div>
      </div>
      <div
        className="parallax-wrapper-2"
        style={{ backgroundImage: `url(${nextProject.data.cover.url})` }}
      >
        <div className="container-next-project">
          <h1 className="heading white extra">{nextProject.data.title}</h1>
          <Button
            spacer={false}
            buttonClass="button-container next-project1"
            buttonHref={`/case-studies/${nextProject.uid}`}
            buttonHrefClass="button-next-project white w-button w--current"
            buttonLabel="next project"
            buttonLine="button-box white"
          />
        </div>
      </div>
    </>
  );
};

export default Project;
