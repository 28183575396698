import React from "react";
import PropTypes from "prop-types";

const Banner = (props: any) => (
  <div className="div-block-96">
    <img
      src={props.src}
      alt={props.alt}
      className={props.class || "realtime-screen"}
    />
  </div>
);

export default Banner;

Banner.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  class: PropTypes.string,
};
