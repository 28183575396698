import React from "react";
import PropTypes from "prop-types";
import WrapperDetails from "../WrapperDetails";

const Image = (props: any) => (
  <div className="div-block-82">
    <img
      src={props.image}
      srcSet={props.srcSet}
      sizes={props.sizes}
      alt={props.alt}
      className={props.imageClass || "image-12"}
    />
  </div>
);

const ProjectContainer = (props: any) => (
  <div
    className={
      props.leftSection ? "container-realtime-info" : " container-project-info"
    }
  >
    {props.leftSection ? <Image {...props} /> : null}
    <WrapperDetails
      type={props.leftSection ? "wrapper-realtime" : "wrapper-project-details2"}
      role={props.roleDescription}
      summary={props.projectSummary}
      technologies={props.projectTechnologies}
    />
    {props.rightSection ? <Image {...props} /> : null}
  </div>
);

export default ProjectContainer;

ProjectContainer.propTypes = {
  leftSection: PropTypes.bool,
  rightSection: PropTypes.bool,
  image: PropTypes.string,
  srcSet: PropTypes.string,
  sizes: PropTypes.string,
  alt: PropTypes.string,
  imageClass: PropTypes.string,
  wrapper: PropTypes.string,
  Description: PropTypes.string,
  Summary: PropTypes.string,
  Technologies: PropTypes.string,
  projectSummary: PropTypes.string,
  projectTechnologies: PropTypes.string,
};
