import React from "react";
import PropTypes from "prop-types";
import { FadeIn } from "../../components/Animations";
import { AnimatedScroll } from "../../components/HeroContainer";

const ParallaxWrapper = (props: any) => (
  <div className="parallax-wrapper">
    <div
      data-w-id="377b264d-2a16-678f-2205-81e10f06b6ca"
      className={props.classType ? props.classType : `highpitch-bac`}
      style={{ backgroundImage: `url(${props.backgroudImage})` }}
      title={props.alt}
    >
      <h1 className="heading-5">
        <FadeIn duration={3000}>{props.title}</FadeIn>
      </h1>
      <AnimatedScroll className="scroll" initialPose="exit" pose="enter" />
    </div>
    <div className="hazardify" />
  </div>
);

export default ParallaxWrapper;

ParallaxWrapper.propTypes = {
  title: PropTypes.string,
  classType: PropTypes.string,
  alt: PropTypes.string,
  backgroudImage: PropTypes.string,
};
