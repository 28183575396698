import React from "react";
import PropTypes from "prop-types";

const WrapperDetails = (props: any) => (
  <div className={props.type || "wrapper-project-details2"}>
    <div className="details-info">
      <p className="cap-2">Solution</p>
      <p className="p2 full">{props.summary}</p>
    </div>
    <div className="details-info">
      <p className="cap-2">Technologies</p>
      <p className="p2 full">{props.technologies}</p>
    </div>
  </div>
);

export default WrapperDetails;

WrapperDetails.propTypes = {
  type: PropTypes.string,
  role: PropTypes.string,
  summary: PropTypes.string,
  technologies: PropTypes.string,
};
