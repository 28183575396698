import React, { FC } from "react";
import PropTypes from "prop-types";

export interface TaggedTextWithDescriptionProps {
  title?: React.ReactNode;
  subtitle?: string;
  body?: string;
  capClass?: string;
  bodyClass?: string;
  titleClass?: string;
  containerType?: string;
  className?: string;
}

const TaggedTextWithDescription: FC<TaggedTextWithDescriptionProps> = (
  props
) => (
  <div
    className={
      props.className || props.containerType
        ? `container-${props.containerType}`
        : ""
    }
  >
    {!!props.subtitle && (
      <div
        className={`cap ${props.capClass || null}`}
      >{`// ${props.subtitle}`}</div>
    )}
    {!!props.title && (
      <h2 className={`heading ${props.titleClass || "medium"}`}>
        {props.title}
      </h2>
    )}
    {!!props.body && (
      <div
        className={`${props.bodyClass}`}
        dangerouslySetInnerHTML={{ __html: props.body }}
      />
    )}
    {props.children}
  </div>
);

TaggedTextWithDescription.propTypes = {
  containerType: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  titleClass: PropTypes.string,
  body: PropTypes.string,
  bodyClass: PropTypes.string,
  capClass: PropTypes.string,
};

export default TaggedTextWithDescription;
